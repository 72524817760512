import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import WheelFortune from 'wheel-fortune';
import DialogLite from 'dialog-lite';
import signupForm from './utils/signupForm';

const App = {
  wheelFortune: null,
  dialogLite: null,

  registerGSAPPlugins() {
    gsap.registerPlugin(CustomEase);
    WheelFortune.registerGSAP(gsap, CustomEase);
  },

  initWheelFortune() {
    const firstSpin = () => {
      document.body.classList.add('first-spin-complete');
    };

    const secondSpin = () => {
      document.body.classList.add('second-spin-complete');
      setTimeout(() => this.dialogLite.open({ stylingClass: 'dialog-lite--first' }), 2250);
    };

    const spinStates = [
      { stopSegment: 1, callback: () => firstSpin() },
      { stopSegment: 4, callback: () => secondSpin() },
    ];

    this.wheelFortune = new WheelFortune({
      spinStates,
      rotationCount: 4,
      segmentCount: 6,
      wheelLibration: true,
      containerEl: '.wheel-fortune',
      segmentsEl: '.wheel-fortune__segments',
      buttonEl: '.wheel-fortune__button',
    });

    this.wheelFortune.init();
  },

  initDialogLite() {
    this.dialogLite = new DialogLite();
    this.dialogLite.init();

    document.getElementById('modalBait')?.addEventListener('pointerdown', () => {
      this.dialogLite.close();
      setTimeout(() => this.dialogLite.open({ stylingClass: 'dialog-lite--second' }), 750);
    });
  },

  async init() {
    this.registerGSAPPlugins();
    this.initWheelFortune();
    this.initDialogLite();
    await signupForm.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
